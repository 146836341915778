import { style } from './index.style'
import Content from '@/components/templates/Content'
import getConfig from 'next/config'

const { publicRuntimeConfig } = getConfig()
const basePath = publicRuntimeConfig.basePath

const SiteFooter = (): JSX.Element => {
  return (
    <footer css={style.siteFooter}>
      <Content>
        <div css={style.wrapFooter}>
          <div css={style.footerLogo}>
            <a href="https://pressmedia.co.jp/" target="_blank" rel="noreferrer">
              <img src={`${basePath}/common/logo.png`} alt="プレスメディア" />
            </a>
          </div>

          <div css={style.footerSns}>

          </div>
        </div>
        <div css={style.copyright}>
          <p>© pressmedia, Inc. All Rights Reserved.</p>
        </div>
      </Content>
    </footer>
  )
}

export default SiteFooter
