import Link from 'next/link'
import { useToggle } from 'react-use'
//import { Link } from 'react-scroll'
import { style } from './index.style'

const Navigation = (): JSX.Element => {
  const [on, toggle] = useToggle(false)

  return (
    <div css={style.wrapMenu}>
      <button css={on ? [style.open, style.menu] : style.menu} onClick={toggle}>
        <span css={style.bar}></span>
        <span css={style.bar}></span>
        <span css={style.bar}></span>
      </button>

      <nav css={on ? [style.nav, style.smallContent] : style.nav} id="nav">
        <ul css={on ? [style.navList, style.openNavList] : style.navList}>
          <li css={style.navItem}>
            <Link href="/#website">
              <a onClick={toggle}>
                <span css={style.navLink}>WEBサイト制作</span>
              </a>
            </Link>
          </li>

          <li css={style.navItem}>
            <Link href="/#movie">
              <a onClick={toggle}>
                <span css={style.navLink}>動画制作</span>
              </a>
            </Link>
          </li>

          <li css={style.navItem}>
            <Link href="/#drone">
              <a onClick={toggle}>
                <span css={style.navLink}>ドローン</span>
              </a>
            </Link>
          </li>

          <li css={style.navItem}>
            <Link href="/#vr">
              <a onClick={toggle}>
                <span css={style.navLink}>360度・VR</span>
              </a>
            </Link>
          </li>

          <li css={style.navItem}>
            <Link href="/#cms">
              <a onClick={toggle}>
                <span css={style.navLink}>CMS導入</span>
              </a>
            </Link>
          </li>

          <li css={style.navItem}>
            <Link href="/#printing">
              <a onClick={toggle}>
                <span css={style.navLink}>印刷物作成</span>
              </a>
            </Link>
          </li>
        </ul>
      </nav>
    </div>
  )
}

export default Navigation
