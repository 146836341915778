import { css } from 'styled-components'
import { minMq } from '@/styles/breakPoints'
import variables from '@/styles/variables'
import { fontSize } from '@/styles/mixin'

const thisSizePc = '122px'
const thisSizeSp = '70px'

const scrollTopBtn = css`
  background-color: ${variables.SUB_C};
  border-radius: 50%;
  bottom: 16px;
  color: ${variables.WHITE};
  height: ${thisSizeSp};
  position: fixed;
  right: 16px;
  transition: all 0.6s;
  width: ${thisSizeSp};
  z-index: 99;

  ${minMq('MD')} {
    background-color: transparent;
    color: #fef6e5;
    height: auto;
    position: fixed;
    right: 16px;
    right: 20px;
    width: ${thisSizePc};
    z-index: 99;
  }

  &.is-hover {
    animation-direction: alternate;
    animation-duration: 1s;
    animation-iteration-count: infinite;
    animation-name: anim_h;
    animation-timing-function: ease-in-out;
  }

  > span {
    display: none;

    ${minMq('MD')} {
      color: #000;
      display: block;
      ${fontSize(14)}
      font-weight: 700;
      position: absolute;
      text-align: center;
      text-decoration: none;
      top: -25px;
      width: 100%;
    }
  }

  > img {
    padding: 5px;
  }

  @keyframes anim_h {
    0% {
      transform: translate(0);
    }

    to {
      transform: translateY(-15px);
    }
  }
`

const activeStyle = css`
  opacity: 1;
`
const hiddenStyle = css`
  opacity: 0;
  pointer-events: none;
`

export const style = {
  scrollTopBtn,
  activeStyle,
  hiddenStyle,
}
