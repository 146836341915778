import { css } from 'styled-components'
import { minMq, maxMq } from '@/styles/breakPoints'
import variables from '@/styles/variables'
import { fontSize } from '@/styles/mixin'

const common = css`
  body {
    ${minMq('MD')} {
      margin: auto;
      min-width: 1030px;
    }
  }

  main {
    flex: 1;
  }

  h2 {
    width: fit-content;
    padding-bottom: 10px;
    margin-bottom: -10px;
    ${fontSize(30)}
    font-style: normal;
    font-weight: 700;
    line-height: 1.2;

    span {
      width: -webkit-fit-content;
      width: -moz-fit-content;
      width: fit-content;
      padding-bottom: 10px;
      margin-bottom: -10px;
      ${fontSize(45)}
      font-style: normal;
      font-weight: 700;
    }
  }

  h3 {
    color: ${variables.BASE_BDC};
    ${fontSize(24)}
    font-weight: 700;
    margin-bottom: 20px;
    text-align: center;

    ${minMq('MD')} {
      ${fontSize(32)}
      margin-bottom: 40px;
    }
  }

  a {
    cursor: pointer;
    text-decoration: none;

    img,
    span {
      transition: all 0.2s;
      opacity: 1;
    }

    &.is-hover {
      img,
      span {
        opacity: 0.75;
      }
    }

    img,
    span {
      display: block;
      max-width: 100%;
      pointer-events: none;
    }
  }

  button {
    cursor: pointer;
  }

  [data-mq='sp'] {
    ${minMq('MD')} {
      display: none !important;
    }
  }

  [data-mq='pc'] {
    ${maxMq('MD')} {
      display: none !important;
    }
  }
`

export default common
