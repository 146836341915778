import { css } from 'styled-components'
import { minMq } from '@/styles/breakPoints'
import { fontSize } from '@/styles/mixin'
import variables from '@/styles/variables'

const siteHeader = css`
  align-items: center;
  background-color: ${variables.WHITE};
  column-gap: 30px;
  display: flex;
  height: 80px;
  justify-content: space-between;
  left: 0;
  padding: calc(${variables.UNIT} * 2);
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 99;
  ${minMq('MD')} {
    height: 60px;
  }
`

const siteTitle = css`
  align-items: center;
  display: flex;
  flex-grow: 1;
  position: static;
  z-index: 1;

  ${minMq('MD')} {
    margin-left: 40px;
  }

  img {
    height: 30px;
    width: auto;
  }
`

const contact = css`
  font-family: Yusei Magic, sans-serif;
  height: 40px;
  width: 140px;

  ${minMq('MD')} {
    height: 50px;
    width: 250px;
  }

  a {
    align-items: center;
    background-color: ${variables.THEME_C};
    border-radius: 40px;
    color: ${variables.WHITE};
    display: flex;
    flex-wrap: wrap;
    height: 100%;
    justify-content: center;
    position: relative;
    transition: all 0.2s;
    width: 100%;

    &.is-hover {
      background-color: ${variables.BTN_TXC};
      transform: scale(1.1);
      span {
        opacity: 1;
      }
    }

    img {
      animation-direction: alternate;
      animation-duration: 3s;
      animation-iteration-count: infinite;
      animation-name: fuwafuwa;
      animation-timing-function: ease-in-out;
      display: none;
      height: auto;
      left: 65px;
      margin-right: 10px;
      position: absolute;
      top: 8px;
      width: 46px;

      ${minMq('MD')} {
        display: block;
      }
    }

    span {
      ${fontSize(16)}
      left: 0;
      line-height: 40px;
      position: absolute;
      text-align: center;
      top: 50%;
      transform: translateY(-50%);
      width: 100%;

      ${minMq('MD')} {
        ${fontSize(18)}
        display: block;
        left: 95px;
        text-align: left;
      }
    }
  }

  @keyframes fuwafuwa {
    0% {
      transform: translate(-50%, 0);
    }

    50% {
      transform: translate(-50%, 0);
    }

    100% {
      transform: translate(-50%, -5px);
    }
  }
`

export const style = {
  siteHeader,
  siteTitle,
  contact,
}
